/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";
import { Spin as Hamburger } from 'hamburger-react'


const Menu = () => {

  const [scrollPosition, setScrollPosition] = useState(0);
  const [isOpen, setOpen] = useState(false);


  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
  <>
  <div className={`hamburgerMenu ${isOpen ? "open" : "" }`}>
    <Hamburger label="Show menu" toggled={isOpen} toggle={setOpen} color={scrollPosition > 450 ? "#000" : "#fff"} direction="right" rounded={true}
    />
  </div>
  <nav className={`menu ${scrollPosition > 450 && "scrolling"} ${isOpen ? "open" : "" }`}>
    <div className="menu-wrapper">
      <ul>
        <li>
          <a href="#details" onClick={() => setOpen(false)}>
            Details
          </a>
        </li>
        <li>
            <a href="#story" onClick={() => setOpen(false)}>
             Our story
            </a> 
        </li>
        <li>
          <a href="#photos" onClick={() => setOpen(false)}>
            Photos
          </a>
        </li>
        <li>
          <a href="#q-a" onClick={() => setOpen(false)}>
            Q & A
          </a>
        </li>
        <li>
          <a href="#rsvp" onClick={() => setOpen(false)}>
           RSVP
          </a>
        </li>
        <li>
          <a href="#registry" onClick={() => setOpen(false)}>
           Registry
          </a>
        </li>
      </ul>
    </div>
  </nav>
  </>
  )
};

export default Menu;
export { Menu };
