/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Menu } from "../Menu";
import { Details } from "../Details";
import { Story } from "../Story";
import { Qa } from "../Qa";
import { Photos } from "../Photos";
import { Registry } from "../Registry";
import { Rsvp }  from "../Rsvp";

const Home = () => (
  <>
  <Menu />
    <header>
        <div className="heading">
          <h1>Shaina &amp; Daniel</h1>
        </div>
    </header>

    <Details />
    <Story />
    <Photos />
    <Qa/>
    <Rsvp />
    <Registry/>
  </>
);

export default Home;
export { Home };
