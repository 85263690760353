/* eslint-disable react/no-unescaped-entities */
import React from "react";
import levy from "../assets/16photos4across4down/lls1.JPG";
import levy2 from "../assets/16photos4across4down/lls2.jpg";
import levy3 from "../assets/16photos4across4down/lls3.jpeg";
import levy4 from "../assets/16photos4across4down/lls4.jpeg";
import levy5 from "../assets/16photos4across4down/lls5.JPG";
import levy6 from "../assets/16photos4across4down/lls6.jpg";
import levy7 from "../assets/16photos4across4down/lls7.jpg";
import levy8 from "../assets/16photos4across4down/lls8.JPG";
import levy9 from "../assets/16photos4across4down/lls9.JPG";
import levy10 from "../assets/16photos4across4down/lls10.JPG";
import levy11 from "../assets/16photos4across4down/lls11.JPG";
import levy12 from "../assets/16photos4across4down/lls12.jpg";
import levy13 from "../assets/16photos4across4down/lls13.jpg";
import levy14 from "../assets/16photos4across4down/lls14.jpg";
import levy15 from "../assets/16photos4across4down/lls15.jpeg"
import levy16 from "../assets/16photos4across4down/lls16.jpg"



const Photos = () => (
  <section id="photos" className="photos">
    <div className="wrapper">
      <h2>Photos</h2>
      <div className="photos__container">
        <img src={levy} alt="Shaina and Daniel" /><img src={levy2} alt="Shaina and Daniel" /><img src={levy3} alt="Shaina and Daniel" /><img src={levy4} alt="Shaina and Daniel" /><img src={levy5} alt="Shaina and Daniel" /><img src={levy6} alt="Shaina and Daniel" /><img src={levy7} alt="Shaina and Daniel" /><img src={levy8} alt="Shaina and Daniel" /><img src={levy9} alt="Shaina and Daniel" /><img src={levy10} alt="Shaina and Daniel" /><img src={levy11} alt="Shaina and Daniel" /><img src={levy12} alt="Shaina and Daniel" /><img src={levy13} alt="Shaina and Daniel" /><img src={levy14} alt="Shaina and Daniel" /><img src={levy15} alt="Shaina and Daniel" /><img src={levy16} alt="Shaina and Daniel" />
      </div>
    </div>
  </section>
);

export default Photos;
export { Photos };