import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Rsvp = () => {
  const [data, setData] = useState(null);
  const [isAttending, setIsAttending] = useState("undetermined");
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();

  const postData = async (url, data) => {
    await fetch(url, {
      method: "POST",
      mode: "no-cors",
      headers: { "Content-Type": "application/json" },
      body: data,
    }).then(() => setData(data));
  };

  const handleSubmit = (e) => {
    e.preventDefault(e);

    // extract form data
    const formdata = new FormData(e.target);

    // convert FormData to json object
    const json = {};
    formdata.forEach(function (value, prop) {
      json[prop] = value;
    });

    postData(
      "https://script.google.com/macros/s/AKfycbwU8tY2b-sNQmbwQs4dSoy0KUv7hmZu2NJMvSqIewhbUacsRW6o/exec",
      formdata,
    );
    setIsDisabled(true);
  };

  useEffect(() => {
    if (data) {
      navigate("/confirmation");
    }
  }, [data]);

  const handleButtonSubmit = () => {
    if (isDisabled) setIsDisabled(false);
  };

  return (
    <section id="rsvp" className="rsvp">
      <div className="wrapper">
        <h2 className="rsvp__title">RSVP</h2>
        <form className="rsvp__form" onSubmit={handleSubmit} data-email="example@email.net">
          <div className="rsvp__input-group">
            <label htmlFor="name" className="rsvp__label">
              Name(s) of all persons attending: <span>*</span>
            </label>
            <input name="name" id="name" className="rsvp__input" required />
          </div>
          <div className="rsvp__input-group">
            <label htmlFor="email" className="rsvp__label">
              Email Address: <span>*</span>
            </label>
            <input name="email" id="email" className="rsvp__input" required />
          </div>
          <div className="rsvp__input-group">
            <fieldset className="rsvp__fieldset">
              <legend className="rsvp__label">
                Attending? <span>*</span>
              </legend>
              <div className="rsvp__input-container">
                <div className={`rsvp__btn ${isAttending === "yes" ? "active" : ""}`}>
                  <label htmlFor="attending">Yes</label>
                  <input
                    type="radio"
                    name="attending"
                    id="attending"
                    className="rsvp__input-radio"
                    value="attending"
                    onChange={() => {
                      setIsAttending("yes");
                    }}
                    required
                  />
                </div>
                <div className={`rsvp__btn ${isAttending === "no" ? "active" : ""}`}>
                  <label htmlFor="notAttending">No</label>
                  <input
                    type="radio"
                    name="attending"
                    id="notAttending"
                    className="rsvp__input-radio"
                    value="not attending"
                    onChange={() => setIsAttending("no")}
                  />
                </div>
              </div>
            </fieldset>
          </div>
          {isAttending === "yes" && (
            <>
              <div className="rsvp__input-group">
                <label htmlFor="allergies" className="rsvp__label">
                  Please let us know of any food allergies/special dietary restrictions (vegetarian, etc...)
                </label>
                <input type="text" name="allergies" className="rsvp__input" id="allergies" />
              </div>
            </>
          )}
          <div className="rsvp__input-group">
            <label htmlFor="song" className="rsvp__label">
              Song request? No promises
            </label>
            <input type="text" name="song" id="song" className="rsvp__input" />
          </div>
          <div className="rsvp__input-group">
            <label htmlFor="message" className="rsvp__label">
              Write anything you want here!
            </label>
            <input type="text" name="notes" id="message" className="rsvp__input" />
          </div>
          <div className="rsvp__input-group">
            <button
              type="submit"
              className="rsvp__send"
              onClick={handleButtonSubmit}
              disabled={isDisabled}>
              Send RSVP
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Rsvp;
export { Rsvp };