/* eslint-disable react/no-unescaped-entities */
import React from "react";

const Registry = () => (
  <section id="registry" className="registry">
    <div className="wrapper">
      <h2><a target="_blank" href="https://www.myregistry.com/wedding-registry/daniel-levy-and-shaina-wajchendler-north-york-on/3102716/giftlist" rel="noreferrer">Registry</a></h2>
    </div>
  </section>
);

export default Registry;
export { Registry };