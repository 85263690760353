import React from "react";
import heart from "../assets/love-hand-drawn-heart-symbol-outline.png";

const Story = () => (
  <section className="our-story" id="story">
    <div className="wrapper">
        <h2>Our story</h2>
        <img className="heart" src={heart} alt="just a little heart icon"/>
        <p>YOOOOO! Let’s Party!</p>
        <p>But before we do, please familiarize yourselves with The Love Story of Shaina & Daniel, AKA Levy.</p> 
        <p>Shaina & Daniel first met at Pearson Airport in May of 2007. They were both going on a free trip to Israel with their friends. The two friend groups instantly clicked and they remained friends when they got home. It wasn’t long before Shaina became an official “Krew” member and she and Daniel even ended up attending Ryerson at the same time. As Shaina and Daniel were always the last ones to leave the chill, they ended up spending a lot of time alone together. The two quickly became inseparable: they basically lived together, shared a car, “studied” for the same exams, and stayed up all night playing Call of Duty and Settlers of Catan while listening to Panic At the Disco.</p> 
        <p>As time passed, the chills got smaller, friends moved away, people got into serious relationships, you know, adult shit, so there were less people hanging out. Sometime during those years, they became more than friends… what exactly were they? No one knows. But, what we do know is that they gradually grew closer and closer.</p>
        <p>At some point, they woke up, realized that they were dating, but had not told any of their friends. This went on for a while until they finally admitted what everyone already knew - they were dating. Were they boyfriend and girlfriend? Unclear. It took some time but eventually, probably around 2014/15, Daniel and Shaina were officially bf and gf. They had no anniversary date, no clue how long they had “been together”, but damnit, they were happy!</p> 
        <p>Their connection continued to grow stronger over time. As they sat back and watched most of their friends get married, they realized that they couldn’t imagine living the rest of their lives without each other, and, although it was unspoken, they knew they were in this for life. Finally, on November 12, 2021, Daniel proposed! Shaina (knew it was coming) said “YES!”, and Daniel was very happy (Shaina too!).</p>
        <p>On August 13, 2022 this unconventional love story gets its next chapter. Best of all, Shaina and Daniel will finally have an anniversary and the very official title of Husband and Wife!</p>
    </div>
  </section>
);

export default Story;
export { Story };
