/* eslint-disable react/no-unescaped-entities */
import React from "react";

const Qa = () => (
  <section id="q-a" className="qa">
    <div className="wrapper">
      <h2>Q & A</h2>
      <p className="qa__q">Q: Dress code?</p>
      <p className="qa__a">A: Formal.</p>
      <p className="qa__q">Q: Parking?</p>
      <p className="qa__a">A: Free, but limited spots.</p>
      <p className="qa__q">Q: Can I bring a plus one?</p>
      <p className="qa__a">A: We are having a small and intimate wedding with our closest friends and family. Due to limited space in our venue, we unfortunately cannot allow all of our guests a plus one. We ask that only the names of the guests on your invitation attend.</p>
      <p className="qa__q">Q: Are kids welcome?</p>
      <p className="qa__a">A: While we adore your little ones, we ask that you leave them at home with a sitter. If you have an exceptional situation, please call Shaina or Daniel.</p>
      <p className="qa__q">Q: Food?</p>
      <p className="qa__a">A: Guests will be offered beef, chicken and salmon. If you have any dietary restrictions, please let us know in your RSVP! </p>
      <p className="qa__q">Q: Open bar?</p>
      <p className="qa__a">A: Yes.</p>
    </div>
  </section>
);

export default Qa;
export { Qa };