import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Home } from "./Home";
import { Confirmation } from "./Confirmation";
import "./styles/App.scss";

function App() {
  return (
    <div className="App">
        <Router>
          <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route exact path="/confirmation" element={<Confirmation />} />
          </Routes>
      </Router>
     
    </div>
  );
}

export default App;
