import React from "react";
import  weddingArch  from "../assets/weddingArch.png";
 
const Details = () => (
  <section className="wedding-details" id="details">
    <div className="wrapper"> 
        <h2>Wedding details</h2>
        <h3>August 13th, 2022</h3>
        <div className="wedding-details__container">
          <h4>Ceremony</h4>
          <img src={weddingArch} aria-hidden="true"/>
          <p className="wedding-details__time"> 5:30 PM</p>
          <p className="wedding-details__location-name">Detailz Couture</p> 
          <p className="wedding-details__address"><span>1 Arrow Road</span><span><span>North York, Ontario</span>M9M 2L4</span></p>
          <a href="https://www.google.ca/maps/place/Detailz+Couture/@43.7367387,-79.5300658,17z/data=!3m1!4b1!4m5!3m4!1s0x882b305af68f0703:0x13aedaa6d259bbeb!8m2!3d43.7368165!4d-79.5279096" target="_blank" rel="noreferrer">Google maps link</a>
          <h5>Reception to follow</h5>
        </div>
    </div>
  </section>
);

export default Details;
export { Details };